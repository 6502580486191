import "./_style.less";
import { FC } from "react";
import { Spin } from "antd";

interface IVuiContentLoading {
  loading: boolean;
}

const VuiContentLoading: FC<IVuiContentLoading> = ({ loading }) => {
  return (
    <div className="vui-content-loading">
      <Spin size="large" spinning={loading} />
    </div>
  );
};

export default VuiContentLoading;
