import Modal from "antd/lib/modal";
import { useTranslation } from "react-i18next";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import Button from "antd/lib/button";
import { VuiModalConfirmationProps } from "./interface";

const VuiModalConfirmation = (props: VuiModalConfirmationProps) => {
  const { t } = useTranslation();
  const {
    show,
    onSubmit,
    onCancel,
    title = t("modal.confirmation.title"),
    subtitle = t("modal.confirmation.subtitle"),
    cancelBtnLabel = t("common.button.cancel"),
    submitBtnLabel = t("common.button.delete"),
    headerTitle = t("common.text.confirmation"),
    isSubmitDanger = true,
    isLoading = false,
  } = props;

  return (
    <Modal
      title={headerTitle}
      centered
      visible={show}
      onCancel={onCancel}
      footer={[
        <Button key="cancel-button" onClick={onCancel}>
          {cancelBtnLabel}
        </Button>,
        <Button
          key="delete-button"
          type="primary"
          danger={isSubmitDanger}
          loading={isLoading}
          onClick={onSubmit}
        >
          {submitBtnLabel}
        </Button>,
      ]}
    >
      <div className="text-center">
        <Title level={5} className="mb0">
          {title}
        </Title>
        <Text>{subtitle}</Text>
      </div>
    </Modal>
  );
};

export default VuiModalConfirmation;
