import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import Upload from "antd/lib/upload";
import React, { useEffect } from "react";
import { toFileList } from "./function";
import useMediaService from "../../hooks/use-media-service";
import { VuiUploadImageProps } from "./interface";
import { useTranslation } from "react-i18next";
import VuiButton from "../button";

const VuiUploadImage = ({
  value = [],
  onChange,
  disabled,
  max = 1,
  label,
  isUploadFirst = true,
  accept = "image/*",
  listType = "picture-card",
}: VuiUploadImageProps) => {
  const { storeMedia } = useMediaService();
  const { t } = useTranslation();
  const [fileList, setFileList] = React.useState<any>(value);
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    setFileList(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.length]);

  const handleOnChange: any = ({ fileList: newFileList }: any) => {
    let originalsFileObjects = newFileList
      .map((item: any) => {
        return item?.originFileObj;
      })
      .filter((file: any) => file !== undefined);

    const apiFiles = newFileList.filter((item: any) => item?.id);

    if (isUploadFirst && originalsFileObjects?.length > 0) {
      setIsLoading(true);
      const formData = {
        files: originalsFileObjects,
        path: "file",
        disk: "public",
      };

      storeMedia(formData)
        .then((response: any) => {
          const uploadedFiles = toFileList(response);
          setFileList([...apiFiles, ...uploadedFiles]);
          onChange?.([...apiFiles, ...uploadedFiles]);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setFileList(newFileList);
      onChange?.(newFileList);
    }
  };

  const handleOnPreview = async (file: any) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();

    image.src = src;
    const imgWindow = window.open(src);

    if (imgWindow) {
      imgWindow.document.write(
        `<body style="margin: 0"><iframe src="${src}" style="display: block;" width="100%" height="100%" frameBorder="0"></body>`
      );
    }
  };

  return (
    <>
      <Upload
        disabled={disabled}
        accept={accept}
        listType={listType}
        fileList={fileList}
        onChange={handleOnChange}
        onPreview={handleOnPreview}
        beforeUpload={() => {
          return false;
        }}
      >
        {isLoading ? (
          <LoadingOutlined />
        ) : (
          fileList.length < max &&
          !disabled &&
          (listType === "picture-card" ? (
            `+ ${label ?? t("common.text.upload")}`
          ) : (
            <VuiButton
              buttonProps={{
                icon: <UploadOutlined />,
                size: "middle",
                type: "default",
              }}
              title={t("common.text.upload")}
            />
          ))
        )}
      </Upload>
    </>
  );
};

export default VuiUploadImage;
