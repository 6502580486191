import { RouteObject } from "react-router-dom";
import React from "react";
import AppLayout from "../layouts/app";
import VuiLoadingScreen from "../components/loading-screen";
import UserListPage from "../pages/user/list";
import UserFormPage from "../pages/user/form";
import ProjectListPage from "../pages/project/list";
import ProjectFormPage from "../pages/project/form";
import CustomerListPage from "../pages/customer/list";
import CustomerFormPage from "../pages/customer/form";
import MaintenanceListPage from "../pages/maintenance/list";
import MaintenanceFormPage from "../pages/maintenance/form";
import MaintenanceDetailPage from "../pages/maintenance/detail";
import DomainListPage from "../pages/domain/list";
import DomainFormPage from "../pages/domain/form";
import DomainDetailPage from "../pages/domain/detail";
import InvoiceListPage from "../pages/invoice/list";
import InoviceFormPage from "../pages/invoice/form";
import InvoiceDetailPage from "../pages/invoice/detail";
const Profile = React.lazy(() => import("../pages/profile"));
const ChangePassword = React.lazy(() => import("../pages/change-password"));

const Home = React.lazy(() => import("../pages/home"));

export const appRoutes: RouteObject[] = [
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: (
          <React.Suspense fallback={<VuiLoadingScreen />}>
            <Home />
          </React.Suspense>
        ),
      },
      {
        path: "profile",
        element: (
          <React.Suspense fallback={<VuiLoadingScreen />}>
            <Profile />
          </React.Suspense>
        ),
      },
      {
        path: "change-password",
        element: (
          <React.Suspense fallback={<VuiLoadingScreen />}>
            <ChangePassword />
          </React.Suspense>
        ),
      },
      {
        path: "project",
        children: [
          {
            path: "",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <ProjectListPage />
              </React.Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <ProjectFormPage />
              </React.Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <ProjectFormPage />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: "maintenance",
        children: [
          {
            path: "",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <MaintenanceListPage />
              </React.Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <MaintenanceFormPage />
              </React.Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <MaintenanceDetailPage />
              </React.Suspense>
            ),
          },
          {
            path: ":id/edit",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <MaintenanceFormPage />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: "domain",
        children: [
          {
            path: "",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <DomainListPage />
              </React.Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <DomainFormPage />
              </React.Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <DomainDetailPage />
              </React.Suspense>
            ),
          },
          {
            path: ":id/edit",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <DomainFormPage />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: "invoice",
        children: [
          {
            path: "",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <InvoiceListPage />
              </React.Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <InoviceFormPage />
              </React.Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <InvoiceDetailPage />
              </React.Suspense>
            ),
          },
          {
            path: ":id/edit",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <InoviceFormPage />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: "client",
        children: [
          {
            path: "",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <CustomerListPage />
              </React.Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <CustomerFormPage />
              </React.Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <CustomerFormPage />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: "user",
        children: [
          {
            path: "",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <UserListPage />
              </React.Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <UserFormPage />
              </React.Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <React.Suspense fallback={<VuiLoadingScreen />}>
                <UserFormPage />
              </React.Suspense>
            ),
          },
        ],
      },
    ],
  },
];
