import { Tag, Tooltip } from "antd";
import { hourToMillisecond } from "../../utils/helpers";
import { useCallback, useMemo } from "react";

const humanizeDuration = require("humanize-duration");

const ExpiredStatus = ({ expiredIn = 0 }: { expiredIn: number }) => {
  const statusColor = useMemo(() => {
    const days = Math.round(expiredIn / 24);
    if (days >= 30) {
      return "#62D88F";
    } else {
      if (days < 3) {
        return "#F44336";
      }
      return "#FAAD14";
    }
  }, [expiredIn]);

  const getStatus = useCallback(
    (isFull?: boolean) => {
      return expiredIn > 0
        ? humanizeDuration(hourToMillisecond(expiredIn), {
            largest: isFull ? 4 : 1,
            units: ["y", "mo", "d", "h"],
            round: isFull,
            delimiter: " "
          })
        : "Expired";
    },
    [expiredIn]
  );

  return (
    <Tooltip className="text-capitalize" title={getStatus(true)}>
      <Tag className="text-capitalize" color={statusColor}>
        {getStatus()}
      </Tag>
    </Tooltip>
  );
};

export default ExpiredStatus;
