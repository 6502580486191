import React from "react";

const VuiFolderIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.31.359A1.773 1.773 0 0 0 1.227.9c-.264.263-.45.599-.522.945-.066.315-.066 10.005 0 10.32.14.668.671 1.237 1.35 1.445.173.053.462.055 6.945.055 6.483 0 6.772-.002 6.945-.055a1.976 1.976 0 0 0 1.181-.981c.224-.454.213-.22.213-4.568.001-3.436-.005-3.921-.046-4.123-.139-.672-.576-1.162-1.272-1.429-.165-.064-.18-.064-3.58-.072l-3.413-.008-.967-.803A42.703 42.703 0 0 0 6.952.724a2.404 2.404 0 0 0-.693-.313C6.057.354 5.949.35 4.26.343 3.278.339 2.401.346 2.31.359m3.81 1.328c.131.066 1.391 1.079 1.631 1.312.054.053.033.073-.705.691-.459.385-.826.668-.926.716l-.165.079-2.017.008-2.018.009V2.077l.084-.16a.466.466 0 0 1 .245-.245l.162-.084 1.772.008 1.772.009.165.082m9.627 2.062a.608.608 0 0 1 .304.355c.022.077.029 1.296.023 3.998l-.009 3.888-.09.145a.591.591 0 0 1-.21.207l-.12.063H2.355l-.12-.063a.591.591 0 0 1-.21-.207l-.09-.145-.008-3.114-.008-3.113 2.078-.01c2.063-.01 2.08-.011 2.302-.079.44-.134.617-.254 1.681-1.142l1.005-.839 3.324-.001c3.241-.002 3.326-.001 3.438.057"
      fill="#707070"
      fillRule="evenodd"
    />
  </svg>
);

export default VuiFolderIcon;
