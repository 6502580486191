import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useBreadcrumb } from "../../../context/breadcrumb";
import { BreadcrumbActionType } from "../../../context/breadcrumb/reducer";
import VuiHelmet from "../../../components/helmet";
import UserFormModule from "../../../modules/user/form";

const UserFormPage = () => {
  const { t } = useTranslation();
  const { dispatch } = useBreadcrumb();
  const { id } = useParams();
  const metaTitle = id
    ? t("pages.user.detail.metaTitle")
    : t("pages.user.add.metaTitle");

  useEffect(() => {
    dispatch({
      type: BreadcrumbActionType.Update,
      payload: [
        {
          link: "/",
          title: t("common.text.home"),
        },
        {
          link: "/user",
          title: t("common.text.user"),
        },
        {
          link: `/user/${id || "add"}`,
          title: id ? t("common.text.detail") : t("common.text.add"),
          isDisabled: true,
        },
      ],
    });
  }, [dispatch, t, id]);

  return (
    <>
      <VuiHelmet title={metaTitle} />

      <UserFormModule />
    </>
  );
};

export default UserFormPage;
