import api, { createCancelTokenHandler } from "../utils/services/api.service";
import { ENV } from "../constants";

const UnitRepository = {
  select: function (params: any = null) {
    return api.get(`${ENV.getApiEndPoint()}/api/select/unit`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.select.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(UnitRepository);

export default UnitRepository;
