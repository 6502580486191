import "./_style.less";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect } from "react";
import { useAuth } from "../../context/auth";
import { useNavigate } from "react-router-dom";
import passportService, {
  OAuth2TokenContract,
} from "../../utils/services/passport.service";
import { openNotification } from "../../utils/helpers";
import VuiHelmet from "../../components/helmet";
import VuiContentLoading from "../../components/content-loading";

const AuthSocialCallbackModule = () => {
  const { t } = useTranslation();
  const { fetchUser } = useAuth();
  const navigate = useNavigate();
  const url = new URL(window.location.href);
  const accessToken = url.searchParams.get("access_token");
  const expiresIn = url.searchParams.get("expires_in");
  const tokenType = url.searchParams.get("token_type");
  const message = url.searchParams.get("message");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const handleLogin = useCallback(() => {
    if (accessToken && expiresIn && tokenType) {
      const contract: OAuth2TokenContract = {
        access_token: accessToken,
        expires_in: expiresIn,
        refresh_token: "",
        token_type: tokenType,
      };

      passportService.setToken(contract);
      fetchUser();
    } else {
      navigate("/login");
      openNotification("error", message || "");
    }
  }, [accessToken, expiresIn, tokenType, fetchUser, message]);

  useEffect(() => {
    handleLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="auth-social-callback-page">
      <VuiHelmet title={t("common.text.socialCallback")} />
      <div className="auth-social-callback-wrapper">
        <div>{t("common.text.socialCallback")}</div>
        <div>{t("common.text.pleaseWait")}</div>
        <VuiContentLoading loading />
      </div>
    </div>
  );
};

export default AuthSocialCallbackModule;
