import { TFunction } from "react-i18next";
import { ReactNode } from "react";
import VuiHomeIcon from "../assets/icons/home-icon";
import VuiUserIcon from "../assets/icons/user-icon";
import VuiFolderIcon from "../assets/icons/folder-icon";
import VuiScheduleIcon from "../assets/icons/schedule-icon";
import { IdcardOutlined } from "@ant-design/icons";
import VuiDomainNameIcon from "../assets/icons/domain-name-icon";
import VuiDocumentIcon from "../assets/icons/document-icon";

export type MenuDataType = {
  title: string;
  key: string;
  access: string | string[];
  icon?: ReactNode;
  children?: MenuDataType[];
};

export const generateMenu = (t: TFunction): MenuDataType[] => {
  return [
    {
      title: t("common.text.dashboard"),
      key: "/",
      access: "any",
      icon: <VuiHomeIcon />,
    },
    {
      title: t("common.text.project"),
      key: "/project",
      access: "project.index",
      icon: <VuiFolderIcon />,
    },
    {
      title: t("common.text.maintenance"),
      key: "/maintenance",
      access: "maintenance.index",
      icon: <VuiScheduleIcon />,
    },
    {
      title: t("common.text.domain"),
      key: "/domain",
      access: "domain.index",
      icon: <VuiDomainNameIcon />,
    },
    {
      title: t("common.text.invoice"),
      key: "/invoice",
      access: "invoice.index",
      icon: <VuiDocumentIcon />,
    },
    {
      title: t("common.text.client"),
      key: "/client",
      access: "customer.index",
      icon: <IdcardOutlined style={{ fontSize: 18, color: "#707070" }} />,
    },
    {
      title: t("common.text.user"),
      key: "/user",
      access: "user.index",
      icon: <VuiUserIcon />,
    },
  ];
};
