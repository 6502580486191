import React from "react";

const VuiDocumentIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.265.233C.983.308.785.427.548.664a1.493 1.493 0 0 0-.436.725l-.054.186v14.84l.054.186c.088.304.198.488.436.725.24.24.437.357.726.431.177.045.283.046 5.726.046s5.549-.001 5.726-.046c.289-.074.486-.191.726-.431.233-.233.343-.413.435-.713l.054-.175.007-3.908c.008-3.947-.002-4.668-.076-5.225a8.328 8.328 0 0 0-1.306-3.477A8.229 8.229 0 0 0 9.282.982C8.454.59 7.832.408 6.72.23 6.594.209 5.836.2 3.99.195 1.527.188 1.429.19 1.265.233m4.616 1.382a2.157 2.157 0 0 1 1.625 1.628c.027.114.04.388.052 1.097.018 1.055.019 1.061.206 1.4.116.213.435.523.652.637.328.171.415.183 1.477.202.868.016.959.022 1.114.068.642.19 1.181.684 1.404 1.286.138.374.129.066.136 4.346.008 4.341.018 4.046-.144 4.114-.123.051-10.683.051-10.806 0-.163-.069-.15.565-.15-7.398 0-7.938-.012-7.328.145-7.397.118-.053 4.066-.037 4.289.017m3.065.756a6.456 6.456 0 0 1 1.58 1.175c.389.387.615.657.876 1.051.229.344.498.823.498.886 0 .014-.039.005-.087-.02-.154-.077-.541-.19-.817-.237-.229-.039-.379-.046-1.049-.046-.646 0-.794-.006-.855-.035-.144-.068-.142-.054-.158-1.05-.009-.5-.026-.959-.04-1.018a4.952 4.952 0 0 0-.19-.658 3.305 3.305 0 0 1-.071-.192c0-.023.045-.003.313.144m-5.372 8.477c-.258.089-.387.304-.386.644 0 .312.089.494.295.609l.122.067h6.79l.122-.067c.207-.115.295-.298.294-.612-.001-.248-.056-.403-.186-.523-.188-.173.042-.163-3.635-.162-3.134.001-3.298.003-3.416.044m.001 2.496c-.215.075-.329.223-.378.489-.047.259.019.531.163.675.183.183.134.178 1.934.187 1.702.008 1.92-.002 2.103-.095.263-.134.373-.552.244-.93a.546.546 0 0 0-.291-.309c-.103-.049-.133-.049-1.878-.055-1.672-.005-1.781-.003-1.897.038"
      fillRule="evenodd"
      fill="#707070"
    />
  </svg>
);

export default VuiDocumentIcon;
