import React, { PropsWithChildren } from "react";
import { VuiAccessibleProps } from "./interface";
import { useAuth } from "../../context/auth";
import { canAccess } from "../../utils/helpers";

const VuiAccessible = ({
  access,
  children,
}: PropsWithChildren<VuiAccessibleProps>) => {
  const { state } = useAuth();

  if (state.user && !canAccess(access, state.user?.permissions)) {
    return null;
  }

  if (!access) {
    return null;
  }

  return <>{children}</>;
};

export default VuiAccessible;
