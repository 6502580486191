import { Card, Col, Form, Row, Spin, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useGetUser } from "../hooks/use-get-user";
import Input from "antd/es/input";
import VuiFormActionButton from "../../../components/form-action-button";
import RoleRepository from "../../../repositories/role-repository";
import VuiSelect from "../../../components/select";
import { useCallback, useEffect, useState } from "react";
import VuiPageTitle from "../../../components/page-title";
import { useFormUser } from "../hooks/use-form-user";
import { UserFormData, UserPayloadType } from "./interface";
import { convertToSelectValue } from "../../../components/select/function";
import { ENV } from "../../../constants";
import VuiAccessible from "../../../components/accessible";
import VuiModalConfirmation from "../../../components/modal-confirmation";
import { openNotification } from "../../../utils/helpers";

const { Text } = Typography;

const UserFormModule = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const title = id ? t("pages.user.detail.title") : t("pages.user.add.title");
  const { loadUserData, isOnFetchingUserData, userData } = useGetUser();
  const { submitLoading, onSubmit, deleteLoading, onDelete } = useFormUser();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [form] = Form.useForm();

  const onFinish = useCallback(
    async (data: UserFormData) => {
      const normalizeData: UserPayloadType = {
        name: data.name,
        email: data.email,
        phone_number: data.phone_number,
        role_id: data.role_id.value as number,
        ...(!id ? { url: `${ENV.getHostAppUrl()}/reset-password` } : {}),
      };

      await onSubmit(normalizeData, id);
    },
    [id, onSubmit]
  );

  const handleCloseModalDelete = useCallback(() => {
    setShowDeleteModal(false);
  }, []);

  const handleConfirmDelete = useCallback(async () => {
    if (id) {
      await onDelete(id).then(() => {
        setShowDeleteModal(false);
        openNotification(
          "success",
          t("notification.success.deleteItem", {
            item: t("common.text.user"),
          })
        );
        navigate(`/user`);
      });
    }
  }, [id, navigate, onDelete, t]);

  useEffect(() => {
    if (id) {
      (async () => {
        await loadUserData(id, {
          with: ["roleUser.role"],
        });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id && userData) {
      form.setFieldsValue({
        ...userData,
        role_id: convertToSelectValue(userData.role_user.role),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <>
      <VuiPageTitle title={title} onBackLink="/user" />

      <Spin size="large" spinning={isOnFetchingUserData}>
        <Form form={form} layout={"vertical"} onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col md={16} xs={24}>
              <Card title={t("common.text.information")}>
                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <Form.Item
                      name="name"
                      label={t("common.form.name.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.name.label"),
                          }),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={t("common.form.name.placeholder")}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item
                      name="email"
                      label={t("common.form.email.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.email.label"),
                          }),
                        },
                        {
                          type: "email",
                          message: t("validation.email", {
                            item: t("common.form.email.label"),
                          }),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={t("common.form.email.placeholder")}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item
                      name="phone_number"
                      label={t("common.form.phoneNumber.label")}
                      rules={[
                        {
                          pattern: new RegExp("^[0-9-()+\\s]*$"),
                          message: t("validation.invalid", {
                            item: t("common.form.phoneNumber.label"),
                          }),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={t("common.form.phoneNumber.placeholder")}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24}>
                    <Form.Item
                      name="role_id"
                      label={t("common.form.role.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.role.label"),
                          }),
                        },
                      ]}
                    >
                      <VuiSelect repository={RoleRepository} />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col md={16} xs={24}>
              <VuiFormActionButton
                cancelBtnLink="/user"
                isLoading={submitLoading}
              />
            </Col>

            {id && (
              <VuiAccessible access="project.destroy">
                <Col xs={24}>
                  <Text
                    className="cursor-pointer"
                    onClick={() => setShowDeleteModal(true)}
                    title={t("common.text.delete")}
                    type="secondary"
                  >
                    {t("common.text.delete")}
                  </Text>
                </Col>
              </VuiAccessible>
            )}
          </Row>
        </Form>
      </Spin>

      <VuiModalConfirmation
        show={showDeleteModal}
        isLoading={deleteLoading}
        onSubmit={handleConfirmDelete}
        onCancel={handleCloseModalDelete}
      />
    </>
  );
};

export default UserFormModule;
