import { DatePicker, DatePickerProps } from "antd";

const VuiDatePicker = (props: DatePickerProps) => {
  const {
    format = "DD MMM YYYY",
    size = "large",
    className = "w-100",
    ...other
  } = props;

  return (
    <DatePicker {...other} format={format} size={size} className={className} />
  );
};

export default VuiDatePicker;
