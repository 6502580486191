import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useBreadcrumb } from "../../../context/breadcrumb";
import { BreadcrumbActionType } from "../../../context/breadcrumb/reducer";
import VuiHelmet from "../../../components/helmet";
import ProjectListModule from "../../../modules/project/list";

const ProjectListPage = () => {
  const { t } = useTranslation();
  const { dispatch } = useBreadcrumb();

  useEffect(() => {
    dispatch({
      type: BreadcrumbActionType.Update,
      payload: [
        {
          link: "/",
          title: t("common.text.home"),
        },
        {
          link: "/project",
          title: t("common.text.project"),
          isDisabled: true,
        },
      ],
    });
  }, [dispatch, t]);

  return (
    <>
      <VuiHelmet title={t("pages.project.list.metaTitle")} />

      <ProjectListModule />
    </>
  );
};

export default ProjectListPage;
