import "./_style.less";
import { Space } from "antd";
import VuiButton from "../button";
import { useTranslation } from "react-i18next";
import { VuiFormActionButtonProps } from "./interface";
import { Link } from "react-router-dom";

const VuiFormActionButton = (props: VuiFormActionButtonProps) => {
  const { t } = useTranslation();
  const {
    cancelBtnLabel = t("common.button.cancel"),
    cancelBtnLink,
    submitBtnLabel = t("common.button.submit"),
    showSubmitButton = true,
    showCancelButton = true,
    isLoading = false,
    customSubmit,
  } = props;

  return (
    <div className="vui-form-action-button">
      <Space size={16}>
        {showCancelButton && cancelBtnLink && (
          <Link to={cancelBtnLink}>
            <VuiButton
              title={cancelBtnLabel as string}
              buttonProps={{
                type: "default",
              }}
            />
          </Link>
        )}

        {showSubmitButton &&
          (customSubmit ? (
            customSubmit
          ) : (
            <VuiButton
              title={submitBtnLabel as string}
              buttonProps={{
                htmlType: "submit",
              }}
              isLoading={isLoading}
            />
          ))}
      </Space>
    </div>
  );
};

export default VuiFormActionButton;
