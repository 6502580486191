import { useCallback, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { ICollection } from "../../../utils/interfaces/collection.interface";
import { handleBackendError } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";
import { IPaginationParams } from "../../../utils/interfaces/pagination-params.interface";
import { Invoice } from "../../../models/invoice";
import { IResource } from "../../../utils/interfaces/resource.interface";
import InvoiceRepository from "../../../repositories/invoice-repository";
import { InvoiceDataList } from "../list/interface";

export const useGetInvoice = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<InvoiceDataList[]>([]);
  const [totalData, setTotalData] = useState<number>(0);
  const [isOnFetchingData, setIsOnFetchingData] = useState<boolean>(false);

  const [detailData, setDetailData] = useState<Invoice | null>(null);
  const [isOnFetchingDetailData, setIsOnFetchingDetailData] =
    useState<boolean>(false);

  const loadData = useCallback(async (params: IPaginationParams) => {
    setIsOnFetchingData(true);

    await InvoiceRepository.all(params)
      .then((response: AxiosResponse<ICollection<InvoiceDataList[]>>) => {
        setData(response.data.data);
        setTotalData(response.data.meta.total);
      })
      .catch(() => {})
      .finally(() => {
        setIsOnFetchingData(false);
      });
  }, []);

  const loadDetailData = useCallback(
    async (id: string, params: any = null) => {
      setIsOnFetchingDetailData(true);

      await InvoiceRepository.show(id, params)
        .then((response: AxiosResponse<IResource<Invoice>>) => {
          setDetailData(response.data.data);
        })
        .catch((e: AxiosError) => {
          handleBackendError(e, t("notification.error.default"));
        })
        .finally(() => {
          setIsOnFetchingDetailData(false);
        });
    },
    [t]
  );

  return {
    data,
    totalData,
    loadData,
    isOnFetchingData,
    loadDetailData,
    isOnFetchingDetailData,
    detailData,
  };
};
