import { useCallback, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { handleBackendError, openNotification } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import UserRepository from "../../../repositories/user-repository";
import { useAuth } from "../../../context/auth";
import { IResource } from "../../../utils/interfaces/resource.interface";
import { User } from "../../../models/user";
import { AuthActionType } from "../../../context/auth/reducer";
import { UserPayloadType } from "../form/interface";

export const useFormUser = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const { state, dispatch } = useAuth();

  const onDelete = useCallback(
    async (id: number | string) => {
      setDeleteLoading(true);

      return new Promise((resolve, reject) => {
        UserRepository.delete(id)
          .then((response: AxiosResponse) => {
            resolve(response);
          })
          .catch((e: AxiosError) => {
            handleBackendError(e, t("notification.error.default"));
            reject(e);
          })
          .finally(() => {
            setDeleteLoading(false);
          });
      });
    },
    [t]
  );

  const onSubmit = useCallback(
    async (data: UserPayloadType, id: string | null = null) => {
      setSubmitLoading(true);

      await (id ? UserRepository.update(id, data) : UserRepository.create(data))
        .then((response: AxiosResponse<IResource<User>>) => {
          if (id && String(state.user?.id) === id) {
            dispatch({
              type: AuthActionType.UpdateProfile,
              payload: {
                ...state.user,
                ...response.data.data,
              },
            });
          }

          navigate("/user");
          openNotification(
            "success",
            t(`notification.success.${id ? "updateItem" : "createItem"}`, {
              item: t("common.text.user"),
            })
          );
        })
        .catch((e: AxiosError) => {
          handleBackendError(e, t("notification.error.default"));
        })
        .finally(() => {
          setSubmitLoading(false);
        });
    },
    [t, navigate]
  );

  return {
    onSubmit,
    submitLoading,
    deleteLoading,
    onDelete,
  };
};
