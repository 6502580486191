import { ValueType } from "./interface";

export type ApiSelectValue = {
  id: string | number;
  name: string;
};

export const convertToSelectValue = (
  data: ApiSelectValue | ApiSelectValue[] | null
): ValueType | ValueType[] | null => {
  if (data) {
    if (Array.isArray(data)) {
      return data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
    } else {
      return {
        label: data.name,
        value: data.id,
      };
    }
  }

  return data;
};
