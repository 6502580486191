import { Col, Input, Row, Space, Table, Tabs, Tag, Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import VuiPageTitle from "../../../components/page-title";
import { useTranslation } from "react-i18next";
import VuiButton from "../../../components/button";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table/interface";
import { InvoiceDataList } from "./interface";
import VuiTableActionButton from "../../../components/table-action-button";
import { useGetInvoice } from "../hooks/use-get-invoice";
import VuiAccessible from "../../../components/accessible";
import { IPaginationParams } from "../../../utils/interfaces/pagination-params.interface";
import { overrideTableSortOrder, uiFilterData } from "../../../utils/helpers";
import VuiSelect from "../../../components/select";
import { ValueType } from "../../../components/select/interface";
import {
  INVOICE_STATUS_COLOR,
  LOCALSTORAGE_FILTER_DATA_KEY,
} from "../../../constants";
import _ from "lodash";
import useDebounce from "../../../hooks/use-debounce";
import CustomerRepository from "../../../repositories/customer-repository";
import UserRepository from "../../../repositories/user-repository";
import moment from "moment";
import ConstantRepository from "../../../repositories/constant-repository";
import { useAuth } from "../../../context/auth";
import TabPane from "antd/lib/tabs/TabPane";
import VuiNumberFormat from "../../../components/number-format";

const qs = require("qs-lite");

const { Text } = Typography;

type InvoiceTabType = "default" | "approval";

const tabs: InvoiceTabType[] = ["default", "approval"];

const InvoiceListModule = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useAuth();
  const canApprove = state.user?.permissions.includes("invoice.can_approve");
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, loadData, isOnFetchingData, totalData } = useGetInvoice();
  const [hasInitialize, setHasInitialize] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<InvoiceTabType>(
    (searchParams.get("tab") as InvoiceTabType) || "default"
  );

  const [selectedCustomer, setSelectedCustomer] = useState<
    ValueType | undefined
  >();

  const [selectedProjectManager, setSelectedProjectManager] = useState<
    ValueType | undefined
  >();

  const [selectedStatus, setSelectedStatus] = useState<ValueType | undefined>();

  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce(search, 250);

  const [tableQuery, setTableQuery] = useState<IPaginationParams>({
    page: Number(searchParams.get("page")) || 1,
    per_page: Number(searchParams.get("perPage")) || 10,
    sorted_by: searchParams.get("sortedBy") || "descend",
    order_by: (searchParams.get("orderBy") as string) || "date",
  });

  const handleChangeSearchParams = useCallback(async () => {
    const params = {} as IPaginationParams;

    Object.assign(params, {
      page: tableQuery.page,
    });

    Object.assign(params, {
      perPage: tableQuery.per_page,
    });

    if (debouncedSearch) {
      Object.assign(params, {
        search: debouncedSearch,
      });
    }

    if (tableQuery.order_by) {
      Object.assign(params, {
        orderBy: tableQuery.order_by,
      });
    }

    if (tableQuery.sorted_by) {
      Object.assign(params, {
        sortedBy: tableQuery.sorted_by,
      });
    }

    if (selectedCustomer) {
      Object.assign(params, {
        customer: selectedCustomer.value,
      });
    } else {
      const recentCustomer = uiFilterData.get(
        LOCALSTORAGE_FILTER_DATA_KEY.customer
      );
      if (!recentCustomer) {
        searchParams.delete("customer");
      } else {
        const paramsCustomerIds = _.map(
          searchParams.getAll("customer"),
          (item) => parseInt(item)
        );

        if (paramsCustomerIds.length) {
          const customerFromStorage = uiFilterData.getSelectedFilter(
            recentCustomer,
            paramsCustomerIds
          );

          setSelectedCustomer(customerFromStorage[0]);

          Object.assign(params, {
            customer: customerFromStorage[0]?.value,
          });
        } else {
          uiFilterData.remove(LOCALSTORAGE_FILTER_DATA_KEY.customer);
        }
      }
    }

    if (selectedProjectManager) {
      Object.assign(params, {
        project_manager: selectedProjectManager.value,
      });
    } else {
      const recentProjectManager = uiFilterData.get(
        LOCALSTORAGE_FILTER_DATA_KEY.projectManager
      );
      if (!recentProjectManager) {
        searchParams.delete("project_manager");
      } else {
        const paramsProjectManagerIds = _.map(
          searchParams.getAll("project_manager"),
          (item) => parseInt(item)
        );

        if (paramsProjectManagerIds.length) {
          const projectManagerFromStorage = uiFilterData.getSelectedFilter(
            recentProjectManager,
            paramsProjectManagerIds
          );

          setSelectedProjectManager(projectManagerFromStorage[0]);

          Object.assign(params, {
            project_manager: projectManagerFromStorage[0]?.value,
          });
        } else {
          uiFilterData.remove(LOCALSTORAGE_FILTER_DATA_KEY.projectManager);
        }
      }
    }

    if (selectedStatus) {
      Object.assign(params, {
        status: selectedStatus.value,
      });
    } else {
      const recentStatus = uiFilterData.get(
        LOCALSTORAGE_FILTER_DATA_KEY.status
      );
      if (!recentStatus) {
        searchParams.delete("status");
      } else {
        const paramsStatusIds = _.map(searchParams.getAll("status"), (item) =>
          parseInt(item)
        );

        if (paramsStatusIds.length) {
          const statusFromStorage = uiFilterData.getSelectedFilter(
            recentStatus,
            paramsStatusIds
          );

          setSelectedStatus(statusFromStorage[0]);

          Object.assign(params, {
            status: statusFromStorage[0]?.value,
          });
        } else {
          uiFilterData.remove(LOCALSTORAGE_FILTER_DATA_KEY.status);
        }
      }
    }

    if (canApprove) {
      Object.assign(params, {
        tab: selectedTab,
      });
    }

    const queryParams = qs.stringify(params, { indices: false });

    if (queryParams) {
      setSearchParams(`?${queryParams}`);
    } else {
      navigate("/invoice");
    }

    const tableParams: IPaginationParams = {
      page: params.page,
      per_page: tableQuery.per_page,
      ...(debouncedSearch ? { search: debouncedSearch } : {}),
      ...(tableQuery.order_by
        ? { order_by: tableQuery.order_by }
        : { order_by: "date" }),
      ...(tableQuery.sorted_by
        ? { sorted_by: overrideTableSortOrder(tableQuery.sorted_by) }
        : { sorted_by: "desc" }),
      ...(params.customer ? { customer: params.customer } : {}),
      ...(params.project_manager
        ? { project_manager: params.project_manager }
        : {}),
      ...(params.status ? { status: params.status } : {}),
      for_approval: selectedTab === "approval",
    };

    await loadData(tableParams);

    setHasInitialize(true);
  }, [
    tableQuery,
    debouncedSearch,
    selectedCustomer,
    selectedProjectManager,
    selectedStatus,
    loadData,
    navigate,
    searchParams,
    setSearchParams,
    selectedTab,
    canApprove,
  ]);

  const columns: ColumnsType<InvoiceDataList> = [
    {
      title: t("common.text.date"),
      dataIndex: "date",
      key: "date",
      render: (text) => <Text>{moment(text).format("D MMM YYYY")}</Text>,
    },
    {
      title:
        selectedTab === "default"
          ? t("common.text.number")
          : t("common.text.request"),
      dataIndex: selectedTab === "default" ? "name" : "request_by",
      key: selectedTab === "default" ? "name" : "request_by",
    },
    {
      title: t("common.text.client"),
      dataIndex: "customer_name",
      key: "customer_name",
      sorter: true,
      render: (text, record) => (
        <Space>
          <Text>{text}</Text>
          <Tag style={{ color: "#757575" }}>{record.project_manager_name}</Tag>
        </Space>
      ),
    },
    {
      title: t("common.text.dueDate"),
      dataIndex: "due_date",
      key: "due_date",
      render: (text) => <Text>{moment(text).format("D MMM YYYY")}</Text>,
    },
    {
      title: `${t("common.text.amount")} (Rp)`,
      dataIndex: "total",
      key: "total",
      render: (text) => <VuiNumberFormat value={text} />,
    },
    {
      title: t("common.text.status"),
      dataIndex: "status_name",
      key: "status_name",
      render: (text) => (
        <Tag color={_.get(INVOICE_STATUS_COLOR, text, "blue")}>{text}</Tag>
      ),
    },
    {
      title: t("common.text.action"),
      key: "id",
      className: "table-action",
      fixed: "right",
      width: 110,
      render: (text, record) => {
        return (
          <VuiTableActionButton
            detailLink={`/invoice/${record.id}`}
            detailPermissionName="invoice.show"
            showDelete={false}
          />
        );
      },
    },
  ];

  const handleTableChange = useCallback(
    (pagination: any, filters: any, sorter: any) => {
      const params: IPaginationParams = {
        page: pagination.current,
        per_page: pagination.pageSize,
      };

      if (sorter.order) {
        Object.assign(params, {
          sorted_by: sorter.order,
          order_by: sorter.field,
        });
      } else {
        Object.assign(params, {
          sorted_by: null,
          order_by: null,
        });
      }

      setTableQuery(params);
    },
    []
  );

  const handleOnChangeCustomer = useCallback((value: ValueType) => {
    uiFilterData.save(LOCALSTORAGE_FILTER_DATA_KEY.customer, [value]);
    setSelectedCustomer(value);
    setTableQuery((prevState) => ({
      ...prevState,
      page: 1,
      customer: value,
    }));
  }, []);

  const handleOnChangeProjectManager = useCallback((value: ValueType) => {
    uiFilterData.save(LOCALSTORAGE_FILTER_DATA_KEY.projectManager, [value]);
    setSelectedProjectManager(value);
    setTableQuery((prevState) => ({
      ...prevState,
      page: 1,
      project_manager: value,
    }));
  }, []);

  const handleOnChangeStatus = useCallback((value: ValueType) => {
    uiFilterData.save(LOCALSTORAGE_FILTER_DATA_KEY.status, [value]);
    setSelectedStatus(value);
    setTableQuery((prevState) => ({
      ...prevState,
      page: 1,
      status: value,
    }));
  }, []);

  const handleTabChange = (key: string) => {
    setSelectedTab(key as InvoiceTabType);

    setTableQuery((prevState) => ({
      ...prevState,
      page: 1,
      tab: key,
    }));
  };

  useEffect(() => {
    (async () => {
      await handleChangeSearchParams();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableQuery]);

  useEffect(() => {
    if (hasInitialize) {
      setTableQuery((prevState) => ({
        ...prevState,
        search: debouncedSearch,
        page: 1,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  const renderTabContent = () => {
    const tableColumns = columns.filter((col) =>
      selectedTab === "default" ? true : col.key !== "status_name"
    );
    return (
      <Table
        rowKey={"id"}
        columns={tableColumns}
        dataSource={data}
        loading={isOnFetchingData}
        onChange={handleTableChange}
        pagination={{
          current: tableQuery.page,
          showSizeChanger: true,
          pageSize: tableQuery.per_page,
          total: totalData,
        }}
        size="small"
      />
    );
  };

  return (
    <>
      <VuiPageTitle title={t("pages.invoice.list.title")}>
        <VuiAccessible access="invoice.store">
          <Link to="/invoice/add">
            <VuiButton
              title={t("common.button.requestItem", {
                item: t("common.text.invoice"),
              })}
              buttonProps={{
                icon: <PlusOutlined />,
                size: "middle",
              }}
            />
          </Link>
        </VuiAccessible>
      </VuiPageTitle>

      <Row gutter={[16, 16]} className="mb16">
        <Col className="gutter-row" xs={24} md={8}>
          <Input
            allowClear
            placeholder={t("common.form.search.placeholder")}
            prefix={<SearchOutlined />}
            value={search}
            size="middle"
            onChange={(value) => {
              setSearch(value.target.value);
            }}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]} className="mb16">
        <Col className="gutter-row" xs={24} md={6}>
          <VuiSelect
            repository={CustomerRepository}
            value={selectedCustomer}
            placeholder={t("common.text.selectClient")}
            onChange={handleOnChangeCustomer}
            className="select-filter"
            size="middle"
          />
        </Col>
        <Col className="gutter-row" xs={24} md={6}>
          <VuiSelect
            repository={UserRepository}
            value={selectedProjectManager}
            placeholder={t("common.text.selectProjectManager")}
            onChange={handleOnChangeProjectManager}
            className="select-filter"
            size="middle"
          />
        </Col>
        <Col className="gutter-row" xs={24} md={4}>
          <VuiSelect
            repository={ConstantRepository}
            value={selectedStatus}
            placeholder={t("common.text.selectStatus")}
            onChange={handleOnChangeStatus}
            repositoryParams={{
              for: "status_invoice", // id role project manager
            }}
            labelKey="label"
            className="select-filter"
            size="middle"
          />
        </Col>
      </Row>
      {canApprove ? (
        <Tabs defaultActiveKey={selectedTab} onChange={handleTabChange}>
          {tabs.map((tab) => {
            return (
              <TabPane
                tab={tab === "default" ? "All" : "Waiting for Approval"}
                key={tab}
              >
                {renderTabContent()}
              </TabPane>
            );
          })}
        </Tabs>
      ) : (
        renderTabContent()
      )}
    </>
  );
};

export default InvoiceListModule;
