import api, { createCancelTokenHandler } from "../utils/services/api.service";
import { ENV } from "../constants";

const InvoiceRepository = {
  all(params?: any) {
    return api.get(`${ENV.getApiEndPoint()}/api/invoice`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.all.name].handleRequestCancellation()
          .token,
    });
  },
  show(id: string, params?: any) {
    return api.get(`${ENV.getApiEndPoint()}/api/invoice/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.show.name].handleRequestCancellation()
          .token,
    });
  },
  delete: function (id: number | string, params: any = null) {
    return api.delete(`${ENV.getApiEndPoint()}/api/invoice/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.delete.name].handleRequestCancellation()
          .token,
    });
  },
  select: function (params: any = null) {
    return api.get(`${ENV.getApiEndPoint()}/api/select/invoice`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.select.name].handleRequestCancellation()
          .token,
    });
  },
  create: function (payload: any, params: any = null) {
    return api.post(`${ENV.getApiEndPoint()}/api/invoice`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.create.name].handleRequestCancellation()
          .token,
    });
  },
  update: function (id: number | string, payload: any, params: any = null) {
    return api.put(`${ENV.getApiEndPoint()}/api/invoice/${id}`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.update.name].handleRequestCancellation()
          .token,
    });
  },
  upload: function (id: number | string, payload: any, params: any = null) {
    return api.put(
      `${ENV.getApiEndPoint()}/api/invoice/${id}/upload`,
      payload,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[this.upload.name].handleRequestCancellation()
            .token,
      }
    );
  },
  send: function (id: number | string, payload?: any, params: any = null) {
    return api.put(`${ENV.getApiEndPoint()}/api/invoice/${id}/send`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.send.name].handleRequestCancellation()
          .token,
    });
  },
  paidOff: function (id: number | string, payload?: any, params: any = null) {
    return api.put(
      `${ENV.getApiEndPoint()}/api/invoice/${id}/paid-off`,
      payload,
      {
        params,
        cancelToken:
          cancelTokenHandlerObject[
            this.paidOff.name
          ].handleRequestCancellation().token,
      }
    );
  },
  download(id: string, params?: any) {
    return api.get(
      `${ENV.getApiEndPoint()}/api/invoice/${id}/download`,
      {
        params,
        responseType: "blob",
        cancelToken:
          cancelTokenHandlerObject[this.download.name].handleRequestCancellation()
            .token,
      }
    );
  },
  approve: function (id: number | string, payload?: any, params: any = null) {
    return api.put(`${ENV.getApiEndPoint()}/api/invoice/${id}/approve`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.approve.name].handleRequestCancellation()
          .token,
    });
  },
  reject: function (id: number | string, payload?: any, params: any = null) {
    return api.put(`${ENV.getApiEndPoint()}/api/invoice/${id}/reject`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.reject.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(InvoiceRepository);

export default InvoiceRepository;
