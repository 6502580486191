import React from "react";

const VuiHomeIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.64.617c-.473.102-.303-.049-3.465 3.094L2.244 6.625c-.78.77-1.981 2.028-2.031 2.126-.09.18-.082.35.026.538.148.257.401.431.629.431.214 0 .327-.075.752-.496.224-.222.417-.404.429-.404.011 0 .021 1.57.021 3.489 0 3.811-.007 3.646.173 4.011.108.218.451.558.689.684.368.195.045.185 6.068.185s5.7.01 6.068-.185c.244-.129.582-.467.689-.689.181-.373.173-.199.173-4.006 0-1.919.01-3.489.021-3.489.012 0 .205.182.429.404.425.421.538.496.752.496.228 0 .481-.174.629-.431.108-.188.116-.358.026-.538-.05-.098-1.251-1.356-2.031-2.126l-2.931-2.914C10.194 1.096 9.928.84 9.75.754A1.686 1.686 0 0 0 8.64.617m.459 1.366c.037.018 1.278 1.243 2.758 2.723l2.693 2.689v8.268l-.074.073-.073.074H11.55v-1.823c-.001-1.972 0-1.956-.168-2.3-.107-.219-.419-.544-.642-.669-.352-.198-.396-.203-1.74-.203-1.344 0-1.388.005-1.74.203-.215.121-.533.448-.639.658-.171.337-.17.333-.171 2.311v1.823H3.597l-.073-.074-.074-.073V7.395l2.693-2.689c1.48-1.479 2.719-2.704 2.752-2.722.077-.042.124-.042.204-.001m.935 10.24c.078.032.109.069.134.157.021.075.032.694.032 1.772v1.658H7.8v-1.676c0-1.171.01-1.704.034-1.773.059-.17.114-.179 1.159-.18.745-.001.96.008 1.041.042"
      fillRule="evenodd"
      fill="#707070"
    />
  </svg>
);

export default VuiHomeIcon;
