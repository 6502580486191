import { Helmet } from "react-helmet-async";
import { VuiHelmetProps } from "./interface";

const VuiHelmet = ({ title }: VuiHelmetProps) => {
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

export default VuiHelmet;
