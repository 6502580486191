import { RouteObject } from "react-router-dom";
import React from "react";
import AuthLayout from "../layouts/auth";
import VuiLoadingScreen from "../components/loading-screen";
import AuthSocialCallbackModule from "../modules/social-callback";

const Login = React.lazy(() => import("../pages/login"));
const ForgotPassword = React.lazy(() => import("../pages/forgot-password"));
const ForgotPasswordSuccess = React.lazy(
  () => import("../pages/forgot-password-success")
);
const ResetPassword = React.lazy(() => import("../pages/reset-password"));

export const authRoutes: RouteObject[] = [
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "login",
        element: (
          <React.Suspense fallback={<VuiLoadingScreen />}>
            <Login />
          </React.Suspense>
        ),
      },
      {
        path: "social-callback",
        element: (
          <React.Suspense fallback={<VuiLoadingScreen />}>
            <AuthSocialCallbackModule />
          </React.Suspense>
        ),
      },
      {
        path: "forgot-password",
        element: (
          <React.Suspense fallback={<VuiLoadingScreen />}>
            <ForgotPassword />
          </React.Suspense>
        ),
      },
      {
        path: "forgot-password-success",
        element: (
          <React.Suspense fallback={<VuiLoadingScreen />}>
            <ForgotPasswordSuccess />
          </React.Suspense>
        ),
      },
      {
        path: "reset-password",
        element: (
          <React.Suspense fallback={<VuiLoadingScreen />}>
            <ResetPassword />
          </React.Suspense>
        ),
      },
    ],
  },
];
