import { InvoiceStatus } from "../models/invoice";

export const ENV = {
  getTinyApiKey: () => window._env_.REACT_APP_TINY_API_KEY,
  getApiEndPoint: () => window._env_.REACT_APP_PASSPORT_END_POINT,
  getPassportClientId: () => window._env_.REACT_APP_PASSPORT_CLIENT_ID,
  getPassportClientSecret: () => window._env_.REACT_APP_PASSPORT_CLIENT_SECRET,
  getHostAppUrl: () => window._env_.REACT_APP_URL,
  getAppName: () => window._env_.REACT_APP_NAME,
};

export const LOCALSTORAGE_FILTER_DATA_KEY = {
  customer: "filter:customer",
  projectManager: "filter:project_manager",
  status: "filter:status",
  type: "filter:type",
};

export const MAINTENANCE_STATUS_COLOR: Record<string, string> = {
  "On Going": "#62D88F",
  Expired: "#F44336",
  Cancelled: "#FAAD14",
  "3 Months": "#FAAD14",
  "2 Months": "#FAAD14",
  "1 Month": "#FAAD14",
  "15 Days": "#FAAD14",
  "7 Days": "#FAAD14",
  "3 Days": "#FAAD14",
  "1 Day": "#FAAD14",
};

export const DOMAIN_STATUS_COLOR: Record<string, string> = {
  "On Going": "#62D88F",
  Expired: "#F44336",
  Cancelled: "#FAAD14",
  "3 Months": "#FAAD14",
  "2 Months": "#FAAD14",
  "1 Month": "#FAAD14",
  "15 Days": "#FAAD14",
  "7 Days": "#FAAD14",
  "3 Days": "#FAAD14",
  "1 Day": "#FAAD14",
};

export const INVOICE_STATUS_COLOR: Record<InvoiceStatus, string> = {
  "Waiting For Approval": "#FAAD14",
  Approved: "#0078F0",
  Paid: "#62D88F",
  Due: "#F44336",
  Sent: "#40A9FF",
  Rejected: "#F44336",
};
